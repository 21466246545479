.toast-container {
	position: fixed;
	top: $spacer;
	margin-top: $spacer;
	right: $spacer;
	z-index: $app-content-zindex;
}
.toast {
	height: initial !important;
	color: white !important;

	.toast-header {
		border-bottom: none;
		color: white !important;
		font-weight: bold;
	}

	&.error, &.error > .toast-header {
		background-color: $danger;
	}
	&.warning, &.warning > .toast-header {
		background-color: $warning;
	}
	&.success, &.success > .toast-header {
		background-color: $success;
	}
	&.info, &.info > .toast-header {
		background-color: $gray-800;
	}
	& .toast-header {
		border-bottom-width: $toast-header-border-width;
		border-color: $toast-border-color;
	}
}