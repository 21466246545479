@import 'default/styles';

/* Perfect Scrollbar */
@include media-breakpoint-up(md) {
	.app:not(.app-sidebar-fixed) {
		& .app-sidebar-content {
			max-height: initial;
			height: auto;
		}
	}
}

/* Theme Custom */
.app-sidebar-moble-toggled {
	.app-sidebar {
		left:0;
	}
}

/* Responsive Table (pivot from horizontal to vertical; requires data attributes) */
@media only screen and (max-width: 811px) {
    /* Force table to not be like tables anymore */
    table.table-responsive {
        display: block;

        thead, tbody, th, td, tr {
            display: block
        }
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr {
            border: 1px solid #ccc;
        }

        td {
            /* Behave like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            position: relative;
            padding-left: 50%;
            white-space: normal;
            text-align: left !important;
            width: auto !important;
        }

        td.responsive-wrap {
            padding-left: 0;
        }

        td:before {
            /* Now like a table header */
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 45%;
            padding: 10px;
            white-space: nowrap;
            text-align: left;
            font-weight: bold;
            content: attr(data-title);
        }

        td.responsive-wrap:before {
            position: relative;
            width: 100%;
            content: attr(data-title);
        }

        td.center, td.text-center {
            text-align: left !important;
        }

        td.right, td.text-right, td.text-end {
            text-align: left;
        }

        td > div {
            position: relative;
            /*padding-left: 50%;*/
            white-space: normal;
            text-align: left;
            display: block;
        }

        td div.form-group {
            margin-bottom: 0;
        }

        td.responsive-wrap > div {
            padding-left: 0;
        }
    }
}


/* Countdown Timer */
.countdown-section {
	display: block;
	text-align: center;

  .countdown-show1 & {
    width: 98%;
  }
  .countdown-show2 & {
    width: 48%;
  }
  .countdown-show3 & {
    width: 32.5%;
  }
  .countdown-show4 & {
    width: 24.5%;
  }
  .countdown-show5 & {
    width: 19.5%;
  }
  .countdown-show6 & {
    width: 16.25%;
  }
  .countdown-show7 & {
    width: 14%;
  }
}
.countdown-period {
  display: block;
}


/* Calendar */
.fc {
	& .btn {
		height: auto;
		padding: $btn-padding-y $btn-padding-x;
	}
	& .fc-event,
	& .fc-event-dot {
		background: none;
		border-color: transparent;
	}
}


/* Highlight.js */
.hljs {
	& .hljs-keyword,
	& .hljs-built_in {
		color: darken($blue, 15%);
	}
	& .hljs-class {
		color: lighten($blue, 5%);
	}
	& .hljs-string,
	& .hljs-literal {
		color: darken($red, 7.5%);
	}
	& .hljs-title {
		color: lighten($dark, 20%);
	}
}


/* Material Header Sort */
.mat-sort-header-sorted {
  & .mat-sort-header-button {
    &:focus {
      outline: none;
    }
  }
}


/* Ng Bootstrap Datepicker */
ngb-datepicker {
	body & {
		border: none;

		&.dropdown-menu {
			padding: 5px;
			border-radius: $border-radius;
		}
		& .ngb-dp-header {
			background: none;

			& ngb-datepicker-navigation-select {
				& > .custom-select {
					font-size: 12px;
					height: 26px;
					margin: 0 5px;
				}
			}
		}
		& .ngb-dp-months {
			& .ngb-dp-month {
				& ngb-datepicker-month {
					& .ngb-dp-week {
						border: none;
						background: none !important;

						& .ngb-dp-weekday {
							font-style: initial;
							font-size: 12px;
							font-weight: bold;
							color: var(--app-component-color);
						}
						& .ngb-dp-day {
							font-weight: 600;

							&:focus {
								outline: none;
							}
							& .btn-light {
								color: rgba(var(--app-component-color-rgb), .5);

								&:hover,
								&:focus,
								&:active {
									background: var(--bs-light);
									color: var(--app-component-color);
								}
								&:focus,
								&:active,
								&.active {
									outline: none !important;
									box-shadow: none !important;
								}
								&.bg-primary {
									color: $white;
									background: var(--app-component-active-bg) !important;
								}
							}
						}
					}
				}
			}
		}
		& .custom-select {
			display: block;
			width: 100%;
			padding: rem(3px) rem(20px) rem(3px) rem(10px);
			-moz-padding-start: subtract($form-select-padding-x, 3px);
			font-family: $form-select-font-family;
			@include font-size($form-select-font-size);
			font-weight: $form-select-font-weight;
			line-height: $form-select-line-height;
			color: $form-select-color;
			background-color: $form-select-bg;
			background-image: escape-svg($form-select-indicator);
			background-repeat: no-repeat;
			background-position: right 0.3rem center;
			background-size: $form-select-bg-size;
			border: $form-select-border-width solid $form-select-border-color;
			border-radius: $form-select-border-radius;
			transition: $form-select-transition;
			appearance: none;

			&:focus {
				border-color: $form-select-focus-border-color;
				outline: 0;
				@if $enable-shadows {
					box-shadow: $form-select-box-shadow $form-select-focus-box-shadow;
				} @else {
					// Avoid using mixin so we can pass custom focus shadow properly
					box-shadow: $form-select-focus-box-shadow;
				}
			}
		}
	}
}

/* Ngx-chart */
.ngx-charts {
	body & {
		color: var(--app-component-color);

		& .gridline-path {
			stroke: var(--app-component-border-color);
		}
		& text {
			fill: var(--app-component-color);
		}
	}
}
.chart-legend {
	body & {
		& .legend-title {
			font-weight: $font-weight-bold;
			font-size: $font-size-base;
		}
		& .legend-labels {
			background: var(--bs-light);

			& .legend-label {
				color: rgba(var(--app-component-color-rgb), .5);

				&:hover {
					color: var(--app-component-color);
				}
			}
		}
	}
}


/* Ngx-editor */
.NgxEditor__Wrapper {
	body & {
		border: none;

		border-radius: 0;

		& .NgxEditor__MenuBar {
			background: var(--bs-light);
			border: none;
			padding: rem(10px) $spacer;
			display: flex;
			flex-wrap: wrap;

			& .NgxEditor__MenuItem.NgxEditor__MenuItem--Icon,
			& .NgxEditor__Dropdown  {
				border-radius: $border-radius;

				&:hover,
				&:focus {
					background: var(--app-component-dropdown-hover-bg);
					color: var(--app-component-dropdown-hover-color);
				}
			}
			& .NgxEditor__Dropdown--DropdownMenu {
				background: var(--app-component-dropdown-bg);
			}
		}
		& .NgxEditor__Content {
			padding: $spacer;
			min-height: rem(250px);
		}
		& .NgxEditor {
			background: var(--app-component-bg);
			color: var(--app-component-color);

			& .NgxEditor__Placeholder:before {
				color: var(--app-component-color);
			}
		}
	}
	body.dark-mode & {
		& .NgxEditor__MenuItem--IconContainer {
			filter: invert(1);
		}
	}
}


/* Tagify */
.tagify {
	body & {
		padding-left: rem(6px);
		padding-right: rem(6px);
		border-color: var(--app-component-border-color);
		border-radius: $border-radius;

		&:hover {
			border-color: var(--app-component-border-color);
		}

		& .tagify__tag {
			background: var(--bs-light);
			color: var(--app-component-color);
			border-radius: $border-radius;

			& .tagify__tag__removeBtn {
				color: rgba(var(--app-component-color-rgb), .5);
			}
			& > div {
				font-weight: $input-font-weight;
  			line-height: $input-line-height;
  			padding: rem(2px) rem(6px);
  			color: inherit;

				&:before {
					display: none;
				}
			}
		}
		& .tagify__input {
			line-height: $input-line-height;
			font-weight: $input-font-weight;
			padding: rem(2px) rem(6px);
			margin-left: 0;
			margin-right: 0;
		}
		&.tagify--focus {
			border-color: $input-focus-border-color;
			box-shadow: $input-focus-box-shadow;
		}
	}
}


/* Mailbox */
.mailbox {
	& .mailbox-to {
		& .tagify {
			border: none;
			flex: 1;

			& .tagify__tag {
				background: var(--app-component-active-bg);
				color: var(--app-component-active-color);

				& > div {
					padding-top: rem(3px);
					padding-bottom: rem(3px);
				}
			}
			&.tagify--focus {
				box-shadow: none;
			}
		}
	}
	& .mailbox-input {
		& .NgxEditor__Wrapper {
			& .NgxEditor__MenuBar {
				background: var(--bs-light);
				border-bottom: 1px solid var(--app-component-border-color);
				border-radius: 0;
			}
		}
	}
}


/* Tag Input */
.ng2-tag-input {
  border: 1px solid lighten($border-color, 5%) !important;
  padding: 3px 12px !important;
  min-height: 34px !important;
  border-radius: $border-radius;

  & .ng2-tags-container {
    & tag {
      font-family: inherit;
      font-size: 12px;
      letter-spacing: 0;
      font-weight: 600;
      padding: 3px 8px;
      height: auto;
      line-height: 16px;
      background: var(--app-component-active-bg);
      color: $white;
      border-radius: $border-radius-sm;

      & svg {
        height: 16px !important;
      }
    }
    & .ng2-tag-input__text-input {
      height: 24px;
      font-size: 12px;
      font-weight: 500;
      color: $dark;

			&::placeholder {
				color: lighten($dark, 30%);
			}
    }
  }
}
.inbox .email-to .form-control {
  height: auto;
  min-height: 34px;

  & .ng2-tag-input {
    height: auto !important;
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: -5px;
    margin-bottom: -5px;
  }
}


/* Angular Calendar */
.angular-calendar-inline {
  & .cal-month-view {
  	background: var(--app-component-bg);

    & .cal-header {
      border-bottom: 1px solid var(--app-component-border-color);

      & .cal-cell {
        font-size: 10px;
        text-transform: uppercase;
        color: var(--app-component-color);
        padding: 5px 10px;
      }
    }
    & .cal-days {
      border: none;

      & .cal-cell-row {
      	border-bottom-color: var(--app-component-border-color);

        &:hover {
          background: none;
        }
        & .cal-cell {
          position: relative;
          min-height: initial;

          &:hover,
          &:focus {
            background: var(--app-component-hover-bg);
            color: var(--app-component-color);
          }
          & .cal-cell-top {
            position: relative;
            min-height: initial;

            & .cal-day-number {
              color: rgba(var(--app-component-color-rgb), .5);
              font-size: 12px;
              font-weight: $font-weight-bold;
              margin-right: 3px;
              margin-bottom: 3px;
              margin-top: 20px;
              min-width: 20px;
              min-height: 20px;
              opacity: 1;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            & .cal-day-badge {
              text-indent: 20px;
              width: 5px;
              height: 5px;
              min-width: 3px;
              padding: 0;
              overflow: hidden;
              position: absolute;
              bottom: 25px;
              margin: 0;
              right: 11px;
              background: var(--bs-red);
            }
          }
          & .cal-events {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;

            & .cal-event {
              width: 5px;
              height: 5px;
              display: inline-block;
              margin: 1px;
              background: rgba(var(--app-component-color-rgb), .25);
              border-radius: 50%;
            }
          }
          &.cal-today {
            background: none;

            & .cal-cell-top {
              & .cal-day-number {
                background: var(--app-component-color);
                color: var(--app-component-bg);
              }
            }
          }
          &.cal-event-highlight {
            background: var(--bs-light) !important;
          }
          &.cal-day-cell {
            &:not(:last-child) {
              border-right-color: var(--app-component-border-color);
            }
          }
        }
      }
    }
  }
}


/* Ngx Loading bar */
ngx-loading-bar {
	color: var(--app-theme) !important;

	& .ngx-spinner {
    top: $app-header-height + $app-content-padding-y - 7 !important;
    right: $app-content-padding-x - 20 !important;
    left: auto !important;
	}
	& .ngx-bar {
		background: var(--app-theme) !important;
    height: 2px !important;
	}
}


/* Ngx Daterangepickr Material */
.ngx-daterangepicker-action {
  &.btn {
    & .form-control {
      height: auto;
      width: auto;
      display: inline-block;
      background: none;
      border: none;
      color: inherit;
      padding: 0;
      font-weight: inherit;
    }
  }
}
.md-drppicker.shown {
  color: $body-color;
  font-size: $font-size-base;
  z-index: 1020;
  border: none;
  font-family: inherit;
  padding: 0;
  border-radius: $border-radius-lg;
  box-shadow: 0 6px 30px rgba($black, .2);

  @if $enable-rtl {
    direction: rtl;
    text-align: right;
  }
  &.double {
    @media (max-width: 563px) {
      width: rem(280px);
    }
  }

  &:before {
    display: none;
  }
  &:after {
    border-width: rem(7px);
    top: rem(-7px);
    left: rem(10px);
  }

  & .calendar {
    max-width: inherit;
    padding: rem(15px);
    margin: 0;

    & .calendar-table {
      padding: 0;

      & table {
        & thead {
          & tr {
            & th {
              min-width: auto;
              width: auto;
              height: auto;
              line-height: 1;
              padding: rem(7px) rem(7px);
              color: $dark;
              font-weight: bold;
              border: none;
              display: table-cell;

              &.prev,
              &.next {
                & span {
                  border-color: $dark;
                }
                &:hover,
                &:focus {
                  background: $light;
                  color: $dark;
                }
              }
              &.month {
                & .dropdowns {
                  border: 1px solid $border-color;
                  background: $white;
                  font-size: rem(12px);
                  padding: 1px;
                  height: auto;
                  margin: 0;
                  cursor: default;
                  border-radius: $border-radius;
                  transition: all .2s linear;

                  &:focus {
                    outline: none;
                    border-color: $input-focus-border-color;
                    box-shadow: 0 0 0 0.125rem rgba($input-focus-border-color ,.3);
                  }
                  & + .dropdowns {
                    margin-left: rem(10px);
                  }
                }
              }
            }
            &:last-child {
              & th {
                padding-bottom: rem(14px);
              }
            }
          }
        }
        & tbody {
          & tr {
            & td {
              min-width: auto;
              width: auto;
              height: auto;
              line-height: 1;
              padding: rem(7px) rem(9px);
              font-weight: 600;
              color: $dark;
              border: none;
              opacity: 1;

              &.available {
                border-radius: $border-radius;

                &:hover,
                &:focus {
                  background: $light;
                }
                &.in-range {
                  background: rgba(var(--app-component-active-bg-rgb), .5);
                  color: var(--app-component-active-color);
                  border-radius: 0;
                }
                &.active {
                  background: var(--app-component-active-bg);
                  color: var(--app-component-active-color);
                }
                &.start-date {
                  border-radius: $border-radius 0 0 $border-radius;
                }
                &.end-date {
                  border-radius: 0 $border-radius $border-radius 0;
                }
              }
              &.off {
                background: none;
                color: lighten($dark, 60%);
              }
            }
          }
        }
      }
    }
    &.left {
      padding: rem(10px) !important;

      @media (min-width: 730px) {
        clear: none !important;
        border-left: 1px solid $border-color;
      }

      & .calendar-table {
        padding: 0 !important;
      }
    }
    &.right {
      padding: rem(10px) !important;
      border-left: 1px solid $border-color;

      @media (min-width: 730px) {
        clear: none !important;
      }

      @media (max-width: 563px) {
        border-left: none;
        border-top: 1px solid $border-color;
      }
    }
  }
  & .buttons {
    padding: rem(10px);
    clear: both;
    text-align: right;
    border-top: 1px solid $border-color;
    line-height: 12px;
    vertical-align: middle;
    margin: 0;

    & .btn {
      margin-left: rem(8px);
      font-size: rem(12px);
      font-weight: bold;
      padding: rem(4px) rem(8px);
      line-height: rem(20px);
      background-color: $blue;
      border-color: $blue;
      border-radius: $border-radius-sm;
      box-shadow: none !important;

      @if $enable-rtl {
        margin-left: 0;
        margin-right: rem(8px);
      }

      &.btn-default {
        color: $dark;
        background-color: $light;
        border-color: $light;

        &.clear {
          & svg {
            height: rem(20px) !important;
            width: rem(20px) !important;
            margin-top: rem(-10px) !important;
          }
        }
      }
    }
    & .drp-selected {
      font-weight: 600;
      color: lighten($dark, 30%);
    }
  }
  & .ranges {
    display: none;

    @media (min-width: 730px) {
      float: left;
      min-width: rem(140px);
    }
  }
  &.show-ranges {
    & .ranges {
      position: relative;
      display: block;

      @if $enable-rtl {
        float: right;
      }
      @media (max-width: 991px) {
        float: none;
        margin: 0;
        padding: rem(10px);
        border-bottom: 1px solid $border-color;
        overflow: scroll;
        max-width: rem(560px);

        @if $enable-rtl {
          float: none;
        }
      }

      & ul {
        @media (max-width: 991px) {
          width: auto;
          padding-right: rem(10px);
          display: flex;
          flex-wrap: nowrap;
        }

        & li {
          & button {
            font-weight: 600;
            color: lighten($dark, 30%);
            white-space: nowrap;

            @media (max-width: 991px) {
              margin-right: rem(10px) !important;
              border-radius: $border-radius;
            }
            &:hover,
            &:focus {
              background: $light;
              color: $dark;
            }
            &.active {
              background: var(--app-component-active-bg);
              color: $white;
              margin-right: rem(-1px);
            }
          }
        }
      }
    }
    & .drp-calendar {
      &.left {
        border-left: 1px solid $border-color;

        @if $enable-rtl {
          border-left: none !important;
        }
        @media (max-width: 991px) {
          border-left: none;
        }
      }
      &.right {
        @if $enable-rtl {
          border-right: 1px solid $border-color;
        }
        @media (max-width: 991px) {
          @if $enable-rtl {
            border-right: none;
          }
        }
      }
    }
  }
}


/* Accordion */
.card-accordion {
  & ngb-accordion {
    & .card {
      border: none;

      & + .card {
        border-top: 1px solid var(--app-component-border-color);
      }
      & .card-header {
        padding: 0;

        & > .btn {
          text-decoration: none;
          padding: rem(12px) rem(15px);
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}


/* ngx-color */
.sketch-picker {
	body & {
		background: var(--app-component-dropdown-bg) !important;
		border: none !important;
		box-shadow: none !important;

		& .sketch-fields {
			& input {
				border: 1px solid var(--app-component-dropdown-border-color) !important;
				background: none;
				color: var(--app-component-color);
				box-shadow: none !important;
			}
			& span {
				color: var(--app-component-color) !important;
			}
		}
		& .sketch-swatches {
			border-color: var(--app-component-dropdown-border-color);
		}
	}
}


/* ngx-datatable */
.panel {
	& > .table-responsive .ngx-datatable.bootstrap,
	& > .ngx-datatable.bootstrap {
		& .datatable-header {
			& .datatable-header-inner {
				& .datatable-header-cell {
						&:first-child {
							padding-left: rem(15px);
						}
						&:last-child {
							padding-right: rem(15px);
						}
				}
			}
		}
		& .datatable-body {
			& .datatable-body-row {
				& .datatable-row-center {
					& .datatable-body-cell {
						&:first-child {
							padding-left: rem(15px);
						}
						&:last-child {
							padding-right: rem(15px);
						}
					}
				}
			}
		}
	}
}
.ngx-datatable.bootstrap {
	font-size: $font-size-base;

	& .datatable-header {
		& .datatable-header-inner {
			& .datatable-header-cell {
				padding: $table-cell-padding-y $table-cell-padding-x;
				font-weight: 600;
				border-bottom: 1px solid $table-border-color;
				vertical-align: top;
				line-height: inherit;

				& .datatable-header-cell-template-wrap {
					display: flex;
					align-items: center;

					& .datatable-header-cell-wrapper {
						flex: 1;

						& .datatable-header-cell-label {
							display: block;
							line-height: $line-height-base;
						}
					}
					& .sort-btn {
						@include fontawesome();

						&.datatable-icon-sort-unset:before {
							content: '\f0dc';
							opacity: 0.25;
						}
						&.datatable-icon-up:before {
							color: $component-active-bg;
							content: '\f0de';
						}
						&.datatable-icon-down:before {
							color: $component-active-bg;
							content: '\f0dd';
						}
					}
				}
			}
		}
	}
	& .datatable-body {
		& .datatable-body-row {
			border-top: none;

			&.datatable-row-even {
				background: $table-striped-bg;

				.dark-mode & {
					background: rgba($gray-700, 0.7);
				}
			}
			& .datatable-row-center {
				& .datatable-body-cell {
					padding: $table-cell-padding-y $table-cell-padding-x;
					vertical-align: top;
					border-top: none;
					border-bottom: 1px solid $table-border-color;
					line-height: inherit;
				}
			}
		}
	}
	& .datatable-footer {
		background: none;
		color: inherit;
		margin-top: rem(-3px);

		& .datatable-pager {
			& ul {
				& li {
					& a {
						padding: $pagination-padding-y $pagination-padding-x;
						color: $pagination-color;
						border: 1px solid $pagination-border-color;
						min-width: inherit;
						width: auto;
						height: auto;
						line-height: inherit;
						border-radius: $border-radius;

						&:hover {
							color: $pagination-hover-color;
							background: $pagination-hover-bg !important;
							border-color: $pagination-hover-border-color;
						}
					}
					&.active {
						& a {
							color: $pagination-active-color;
							background: $pagination-active-bg !important;
							border-color: $pagination-active-border-color;
						}
					}
				}
			}
			& .datatable-icon-left,
			& .datatable-icon-skip,
			& .datatable-icon-right,
			& .datatable-icon-prev {
				font-size: inherit;
				line-height: inherit;

				@include fontawesome();
			}
			& .datatable-icon-left:before {
				content: '\f104';
			}
			& .datatable-icon-skip:before {
				content: '\f101';
			}
			& .datatable-icon-right:before {
				content: '\f105';
			}
			& .datatable-icon-prev:before {
				content: '\f100';
			}
		}
	}
}


