/*!
 * Color Admin v5.1.3 (https://seantheme.com/color-admin/)
 * Copyright 2021 SeanTheme
 */

@import 'functions';
@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/ag-grid-enterprise/styles/ag-grid.min';
@import 'node_modules/ag-grid-enterprise/styles/ag-theme-quartz.min';
@import 'mixins';
@import 'root';
@import 'reboot';
@import 'app';
@import 'layout';
@import 'ui';
@import 'pages';
@import 'plugins';
@import 'helper';
@import 'rtl';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

.tippy-content {
	padding: 0px 9px !important;
}
