/* You can add global styles to this file, and also import other style files */
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700';

.taradel-blue {
    color: #0936C5;
}

/* remove arrows from number input */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
